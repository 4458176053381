/* COLORS */

$white:#fff;
$grey1:#f4f4f6;
$grey2:#c3c5d1;
$grey3:#767c8e;
$grey4:#85868f;
$grey5:#2e3349;
$grey6:#212121;
$grey7:#edecf1;
$grey8:#dbdbdb;
$grey9: #d8d4d4;
$grey10: #e5e6eb;
$black:#000000;
$black1:#212121;
$orange:#fb7f3e;
$peach1:#fb5d5d;
$peach2:#fa4c6a;
$peach3:#fb4371;
$peach4:#fa4d6a;
$blue:#0095c4;
$peachShadow: rgb(251, 93, 93);
$blackShadow: rgb(0, 0, 0);

/* gradient */
$primaryGradient:linear-gradient(131deg, #fb7f3e, #fa496e);
$primaryGradient1:linear-gradient(107deg, #fb7f3e, #fa496e);
$primaryGradient2: linear-gradient(98deg, #fb7f3e, #fa496e);
$blueGradient: linear-gradient(102deg, #0ca7d7, #0095c4);
/* FONT-FAMILY */
$primaryFontFamily: 'Montserrat', sans-serif;
$fontFamily1:'HelveticaNeue';

/*FONT SIZES*/
$fontSizeH1: 26px;
$fontSizeH2: 22px;
$fontSizeH3: 18px;
$fontSizeH4: 17px;
$fontSizeH5: 16px;
$fontSizeH6: 14px;
$fontSizeH7: 13px;
$fontSizeH8: 12px;
$fontSizeH9:20px;
$fontSizeH24: 24px;
$fontSizeH15: 15px;
