@import './common.scss';
.fullheight{
    height:100% !important;
}
.img{
    height: 85px;
    width: 85px;
   align-self: center;
}
.imgline{
    height:3px;
    width:100%;
    background: $grey1;
    margin-bottom: 10px;
}
.imagecontent{
    display:flex;
    justify-content: center;   
}
.center-align{
    display:flex;
    justify-content: center; 
    text-align: center;
  }
  .icontext{
    height: 24px;
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH8;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    display:flex;
    justify-content: center;
    color:  $grey3;
}
.center-align.ant-menu-item {
    height:94px;
    width:110px;
    background-color: $white !important;
    border-radius: 20px;   
    padding-left: 16px !important;
}
.ant-menu-item-selected {
    background-image: linear-gradient(131deg, #67dbb4, #67dbb4) !important;
    // background-image: $primaryGradient !important; 
    box-shadow: 0px 6px 8px 0 ($peachShadow, 0.4);

}
.svg{
    width:30px;
    height:30px;
    background-repeat: no-repeat;
    margin-top: 15px;
    text-align: center;
}
.ant-menu-item-selected .colorsvg g, .ant-menu-item-selected .colorsvg g g, .ant-menu-item-selected .colorsvg g use{
   fill:$white;
}
.ant-menu-item-selected .colorsvg path{
    fill:$white;
 }

.colorsvg g, .colorsvg g g, .colorsvg g use{
   fill:$grey4;
} 
.ant-menu-inline .ant-menu-item::after {
    border-right-color: #67dbb4 !important; 
}
.ant-menu-item-selected .icontext{
    color: $white !important;
    font-weight: bold;
}
.sidebar1{
    background-color:$white;
    display:flex;
    flex-direction:column;
    align-items: center;  
    width: 140px !important;
    background-color: $white;
    overflow: scroll;
    overflow-x: hidden;
}

.fullheight {
    .ant-menu-inline .ant-menu-item {  
        height:90px !important;
        width:110px !important;
    }
}
 .ant-layout-sider {
    flex: 0 0 0px !important;
   background-color: $grey1 !important;
    min-width: 140px !important;
    height: 100%;
}

.fullheight{
    .anticon svg{
        width:30px !important;
        height: 30px !important;
        color: $grey4 !important;
    }

    .anticon svg:active, .anticon svg:focus{
        color: $white !important;
    }
 .ant-menu-item-active {
        color: $white !important;
    }
    
}

.ant-layout-sider-zero-width-trigger {
    top: 2px !important;
    right: 79px !important;
    border: 1px solid;
}

/* mobile navbar */
.topNav{
    margin-top: 0px !important;
    border-radius: 0px !important;
    z-index: 999;
    position: fixed !important;
    width: 100%;
    .menuDivision{
        display: flex;
        align-items: center;
        .menu-text-row{
            text-align: center;
            width: 100%;
        }
        .pageName{
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #2f3349;
            padding-right: 22px;
        }
        .anticon svg{
            height: 22px;
            width: 22px;
        }
    }
    .listView{
            display: none;
    }
    .listView.active{
        display: flex;
        margin-top: 30px;
    }
    .fullheight .ant-menu-inline .ant-menu-item.ant-menu-item-selected{
        .icontext{
            font-weight: 600;
        }
    }
    .fullheight .ant-menu-inline .ant-menu-item{
        display: flex;
        justify-content: flex-start;
        width: 100% !important;
        background-image: linear-gradient(131deg, #fafafa, #fafafa);
        height: auto !important;
        a{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icontext{
                margin-bottom: 0px;
                margin-left: 35px;
                height: auto;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
            }
        }
    }
    .ant-menu.fullheight{
        width: 100% !important;
        border: none;
        .imagecontent{
            display: none;
        }
        .imgline{
            height: 0px;
        }
    }
    .sideMenuDiv{
        margin-top: 15px;
    }
    .ant-dropdown-placement-bottomLeft{
        margin-top: 25px;
    }
    .profileDropDown{
        background-color: #f4f4f6;
        border-radius: 7px;
        .profile{
            display: flex;
            justify-content: space-between;
            padding: 8px;
            i{
                margin-left: 0px;
            }
        }
        .listView{
                margin: 0px; 
            }
        ul{
            list-style: none;
            padding: 0px;
            width: 100%;
            li{
                text-align: left;
                padding: 10px 17px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #212121;
            }
        }
        .horizontal-divider{
            background-color: $grey10 !important;
            box-shadow: none;
            margin: 5px 0px;
        }
        .anticon-right svg{
            height: 14px;
            width: 14px;
        }
        .anticon svg{
            height: 18px;
            width: 18px;
        }
    }
}

.desktop-menu{
    height: 100%;
}
