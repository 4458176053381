.profileDetails{
    .side-btn-green{
        background-color: #67dbb4 !important;
    }
    .side-btn-opt{
        padding: 10px 20px;
        border-radius: 20px;
        margin-bottom: 20px;
        border: 1px solid lightgray;
    }
    hr{
        margin: 25px 0px;
    }
    .watermargin{
        padding: 30px 20px;
    }
    .uploadedVideos{
        padding: 30px 0px;
    }
    .profile-left-card{
        border-right: 1px solid #0000004d !important;
        height: 100vh;
    }
    .detail-card {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
        font-weight: 500;
        .ant-card-head-title{
            font-size: 20px;
        }
        .cardFields{
            display: flex;
            align-items: flex-end;
            padding: 0px 20px;
            margin-bottom: 10px;
            .feildData{
                border-bottom: 1px solid #85868f94;
            }
            p {
                margin-bottom: 0px;
                color: #000000de;
            }
        }
        input {
            border-top: none;
            border-left: none;
            border-radius: 0px;
            border-right: none;
            font-size: 16px;
            color: #323232;
            font-weight: 600;
            pointer-events: none;
        }
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
        background-color: white;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
        background-color: #67dbb4 !important;
    }
    .bg-green{
        background-color: #67dbb4 !important;
    }
    
    .ant-card-small{
        width: 500px;
        margin: 30px 0px !important;
        padding: 0px !important;
    }
    
    .ant-select-arrow{
        display:  none;
    }
}

.uploadedVideos{
    .ant-radio-inner::after{
        background-color: #40a9ff;
    }
    .actionBtns{
        width: 10%;
    }
}

.videoBtns {
    background-color: #67dbb4 !important;
    color: #ffffff !important;
    padding: 15px 20px 35px 15px !important;
}

/*table data text center*/
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    text-align: center !important;
}

.uploadedVideos {
    .ant-table-thead > tr > th:nth-child(2), .ant-table-tbody > tr > td:nth-child(2){
        text-align: left !important;
    }
}

.anticon-left {
    margin-right: 10px;
}

.videoModal{
    .react-player{
        padding: 50px 30px 30px;
    }
}

.calender-range-picker{
    width: 100%;
    height: 100% !important; 
    margin-left: 20px !important;
    .ant-calendar-picker-input.ant-input{
        height: 100%;
        .ant-calendar-range-picker-separator{
            vertical-align: middle;
            padding: 5px 0px;
        }
    }
}

.name-search-input{
    height: 100% !important; 
    margin-left: 20px !important;
}
    