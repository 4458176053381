@import './common.scss';
.dashboard-header-left-align{
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH1;
    font-weight: bold;
    color: $grey5;
    margin-left: 0px;
    margin-right: 0px;
    text-align: left;
    margin-bottom:10px;  
}
.dashboard-info-left-align{
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH5;
    text-align: left;
    color: $grey4;
    margin-bottom: 0px !important;
}
.dashboard-dropdown{
    background-color:$grey1;
    height:55px;
    display: flex !important;
    flex-direction: column;
  padding:15px !important;
  padding-right:0px !important;
    justify-content: center;
}
.dashboard-coach-name-dropdown{
    margin-left:10px;
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH6;
    font-weight: 600;
    color: $grey6;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dashboard-notification-divider{
   height:55px;
    width:5px;
    border-right: 2px solid $white;
}
.dashboard-notification{
    height:55px;
    background-color:$grey1;
    display: flex !important;
    align-items: center;
    justify-content: center;
    .ant-badge-count{
        margin-top: 1px;
        margin-right: 0px;
    }
}
.dashboard-myprofile-arrow{
    width: 10.8px;
    height: 18px;
     margin-left:20px;
    padding-right: 20px;
}
.dashboard-calendar-info{
    border-radius: 16px;
    box-shadow: 0 3px 6px 0 rgba($black, 0.08);
    background-color: $white;
}
.dashboard-coach-info{
    border-radius: 16px;
    box-shadow: 0 3px 6px 0 rgba($black, 0.08);
    background-color: $white;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .coach-horizontal-divider{
        height: 1px !important;
        background: #c3c5d1;
    }
}
    .dashboard-coach-img{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
}
.dashboard-svg{
    width: 22.7px;
    height: 30px;

    g, g g, g use{
        fill:$peach3;
    }
}
.dashboard-msg-svg{
    width: 26px;
  height: 18.5px;
  margin-top:2px;
  
  g, g g, g use{
        fill:$peach3;
    }
}
.dashboard-log-header{
    font-family: $primaryFontFamily !important;
color:$peach4;
font-size: $fontSizeH5;
  font-weight: 600;
  cursor: pointer !important;
}
 .space{
     margin-left: 8px;
 }
.dashboard-log-info{
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH6;
    color:$grey5;
}
.dashboard-calendar-margin{
    padding: 20px;
 }
.dashboard-coach-margin{
    padding:20px;
}
.dashboard-calendar-divider{
    border-left: 1px solid $grey2;
    height: 225px;
    width:2px;
    float: right;
    box-shadow: 0 3px 6px 0 rgba($black, 0.08);
    display: flex;
    align-self: center;
}
.dashboard-message{
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.calender-date-row{
    margin: 10px;
}

@media screen and (min-width:568px) and (max-width:1024px){
    .dashboard-message{
        flex-direction: column;
    }
    .dashboard-msg-svg{
        margin-left:-10px;
    }
    .space{
        margin-left:0px!important;
    }
}

@media screen and (max-width:320px){
.dashboard-log-header{
    margin-left: 8px;
}
}

.dashboard-calendar-align{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dashboard-coach-name{
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH9;
    font-weight: bold;
}
.dashboard-personal-coach{
    font-family: $primaryFontFamily !important;
    font-size: 15px;
    
}
.dashboard-log-align{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.dashboard-menu .ant-menu-item{
    height:10px !important;
}
.dashboard-calendar-bg{
    width: 60px;
  height: 60px;
  border-radius: 33px;
  background-color: $grey1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:10px;
  img{
    height: 25px !important;
    width: 25px !important;
    display: unset !important;
  }
}
.calendar-arrow{
    width: 11px;
    height: 18px;
    color: $grey5;
    margin:10px;
}
.calendar-date{
    font-family: $primaryFontFamily !important;
  font-size: $fontSizeH9;
  font-weight: bold;
  line-height: 1.1;
  color: $grey5;
}
.calendar-day{
    font-family: $primaryFontFamily !important;
  font-size: $fontSizeH15;
  line-height: 1.47;
}
.dashboard-menu{
    display: flex;
    background-color:$grey1;
    align-items: center;
    width:100%;
}
.dasboard-padding{
    padding: 8px;
}
.dashboard-dropdown-align{
    display: flex !important;
    align-items: center;
    flex-direction: row;
}
.dashboard-menu-label{
    font-family: $primaryFontFamily !important;
    font-size:$fontSizeH6;
    color: $black !important;
}
.dashboard-alert-size{
    width: 24px;
  height: 24px;
  g, g g, g use{
      fill:$grey4;
  }
}
.dashboard-calendar-size{
    width: 26px;
  height: 26px;
  g, g g, g use{
      fill:$grey5;
  }
}

.dashboard-menu{
    .ant-layout{
        background-color: $grey1 !important;
    }
    .ant-row:hover{
        background-color: $white !important;
    }
    a:hover{
        color: #000 !important;
    }
}
.ant-dropdown-menu{
    background-color: #f4f4f6 !important;
}
.ant-dropdown {
    top:108px !important; 
    margin-left:-25px !important;
}

.ant-dropdown-menu-item:hover{
    background: $white !important;
}
.dashboard-nocoach{
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH9;
    font-weight: bold;
    line-height: 1.1;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dashboard-weight-log-btn{
    background-image:$blueGradient !important;
    color: $white !important;
    font-size: $fontSizeH6;
} 
.dashboard-graph-align-center{
    display: flex !important;
    justify-content: center;
}
.dashboard-graph-label{
  font-family: $primaryFontFamily !important;
  font-size:14px;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  align-items: center;
  padding-top: 10px !important;
  font-weight: normal !important;
}

.log-coach-card{
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
}

//media query
@media screen and (max-width: 767.98px) { 
    .log-coach-card{
        flex-direction: column;
    }
    .dashboard-coach-info{
        margin-top: 20px;
    }
    .dashboardCard{
        display: none !important;
    }
}

@media screen and (min-width:568px) and (max-width:992px){
    .calendar-arrow{
        margin: 5px;
    }
    .calendar-date{
        font-size: 16px;
    }
    .calender-date-row{
        margin: 10px 0px;
    }
}

.profile-div{
 .ant-avatar{
       width: 40px !important;
       display:inline-block!important;
 }
}

.dashboardpage{
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: $white !important;
        background: $primaryGradient !important;
        border-color: $peach2 !important;
    }
}
.dashboard-card{
    width: 100% !important;
    height: 300px !important;
}

.myclientsDropdown{
    background-color:$grey1;
    border-radius: 5px;
  }

  .waterlog{
    border-radius: 16px;
    box-shadow: 0 3px 6px 0 rgba($black, 0.08);
    background-color: $white;
    margin-top:20px;
    margin-bottom: 20px;
}

.watermargin{
    padding: 10px 20px;
    .waterMargin-row {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .align-right{
        text-align: right;
    }
}


@media screen and (max-width: 767.98px) {
    .waterlog{
        .watermargin.p-0{
            padding: 0px;
        }
        .watermargin{
            padding: 20px;
            .titleheader .header{
                text-align: left;
            }
            .info{
                text-align: left;
            }
            .watercount{
                display: block;
                .countbuttons{
                    margin-left: 0px;
                }
            }
            .waterMargin-row {
                flex-direction: column;
            }
            .align-right{
                text-align: left;
            }
        }
    }

    .contentstyle{
        margin-top: 70px !important;
    }
}

.dashboardpage{
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #40a9ff;
        border-color: #40a9ff;
    }
}


