 @import 'common.scss';
 .BN-Logos {
    width: 200px;
  }

  .login{
    .ant-form{
      padding-top: 10px;
    }
    .ant-form-item .ant-form-item-with-help{
      margin-bottom: 10px !important;
    }
    .has-error .ant-form-explain{
      margin-top:5px;
    }
    .ant-btn:hover , .ant-btn:focus, .ant-btn:active{
      height: 56px;
      object-fit: contain;
      border-radius: 14px;
      // background-image: $primaryGradient;
      background-color: #67dbb4;
      margin-top:20px;
    }
    .ant-btn {
      width:80%;
      height: 56px;
      object-fit: contain;
      border-radius: 14px;
      background-color: #67dbb4;
      margin-top:20px;
    }
    .ant-input{
      height: 56px;
      border-radius: 9px;
      background-color: $grey1;
      margin-top:10px;
    }
    .ant-btn:disabled{
      height: 56px;
      object-fit: contain;
      border-radius: 14px;
      opacity: 0.7;
      background-color: #67dbb4 !important;
      margin-top:20px;
    }
    .ant-btn > span {
      display:inline !important;
    }
  }

  .centerAlign{
    text-align: center;
  }

  .login-now {
    height: 26px;
    font-family: $primaryFontFamily;
    font-size: $fontSizeH5;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: center;
    color: $white;
  }

  .login-text{
    margin-bottom: 0px;
    font-family: $primaryFontFamily;
    font-size: $fontSizeH1;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color:$grey5;
  }

  .forgot-text {
    margin-bottom: 2.5rem;
  }

  .flex-center{
    padding: 50px 20px 70px;
    display: flex !important;
    flex-direction: column !important;
    @media screen and (min-width : 576px){
      justify-content: center !important;
    } 
  }

  .forgetpassword-Label {
    height: 22px;
    font-family: $primaryFontFamily;
    font-size: $fontSizeH5;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: $blue;
    margin-top:10px;
  }

  .loginbackground {
    padding: 40px 0px;
    border-radius: 10px;
    box-shadow: 3px 10px 26px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
  }

  .appstore{
    width: 150px !important;
    @media screen and (min-width: 768px) {
      width: 250px !important
    }
    @media screen and (min-width: 576px) {
      width: 250px !important
    }
  }

  .login{
    background-color: $grey1;
    min-height: 100vh !important;
    height: auto !important;
    display: flex !important;
    @media screen and (min-width: 768px) {
      align-items: center;
    }
    @media screen and (min-width: 576px) {
      height: auto !important;
    }
  }

  .ant-input:placeholder-shown{
    font-family: $primaryFontFamily;
    font-size: $fontSizeH5;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: $grey6 !important;
  }

  /*profile*/
  .myprofile-text{
    font-family: $primaryFontFamily;
    font-size: $fontSizeH1;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: normal;
    color: $grey5;
  }

  .myprofile-info{
    font-family: $primaryFontFamily;
    font-size: $fontSizeH24;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $grey5;
    margin-top:22px;
    }
  
  .mydescription{
    font-family: $primaryFontFamily;
    font-size: $fontSizeH5;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: $grey4;
  }
 
  .bottom{
    margin-bottom: 10px;
  }

  .profile-main{
    .ant-form-item{
      margin-bottom:0px !important;
    }
  }

  /* Change Password */

  .pwdbackground{
    padding: 40px 40px;
    background: $white;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  
  .changepassword{
    padding: 10px;
    .ant-btn:disabled{
      width: 100% !important;
      height: 54px;
      object-fit: contain;
      border-radius: 14px;
      opacity: 0.7;
      background-color: #67dbb4 !important;
      margin-top:20px;
    }

    .ant-btn, .ant-btn:focus,  .ant-btn:hover , .ant-btn:active{
      width: 100% !important;
      height: 54px;
      object-fit: contain;
      border-radius: 9px;
      background-color: #67dbb4 !important;
      margin-top:20px;
    }

    .has-error .ant-form-explain{
      margin-top:5px;
    }
  
  }

  .passwordform{
    padding: 30px 0px !important;
    width: 100%;
  }

  .changepassword-text{
    font-family: $primaryFontFamily;
    font-size: $fontSizeH6;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: $grey5;
  }

  .password-input{
    height: 50px !important;
    border-radius: 9px !important;
    background-color: $grey1 !important;
    border-color:$grey1 !important;
  }

  .password-head{
    font-family: $primaryFontFamily;
    font-size: $fontSizeH2;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color:$grey5;
  }

  .savepassword{
    height: 20px;
    font-family: $primaryFontFamily;
    font-size: $fontSizeH15;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  @media screen and (max-width: 575.98px) {
    .login-now {
      height: 26px;
      font-size: 2.5vw;
    }

    .login{
      .ant-btn {
        width:100%;
      }
    }

    .flex-center{
      display: flex !important;
      flex-direction: column !important;
      justify-content: baseline !important;
      padding: 40px 20px;
    }

    .BN-Logos {
      width: 150px;
    }
  }

  .flex-page{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    height: 75vh !important;
  }

  @media screen and (max-width: 767.98px) {
    .pwdbackground{
      padding: 20px 10px;
    }
  }

  .profile-user-loading {
    .ant-spin-dot-item {
      background: $peach4 !important;
  }
  }


