.header-left-align{
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH1;
    font-weight: bold;
    color: $grey5;
    margin-left: 0px;
    margin-right: 0px;
    text-align: left;
    margin-bottom:10px;  
}
.info-left-align{
    font-family: $primaryFontFamily !important;
    font-size: $fontSizeH5;
    text-align: left;
    color: $grey4;
    margin-bottom: 0px !important;
}

.ant-btn {
    font-weight: bold !important;
}

.ant-modal-content{
    border-radius: 16px !important;
    .ant-modal-body {
        padding: 0 !important;
        .ant-btn.save {
            border: none!important;
        }
    }
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.mb-0{
    margin-bottom: 0px !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.pointer {
    cursor: pointer;
}

.justify-center{
    justify-content: center;
}

.justify-start{
    justify-content: flex-start !important;
}

.justify-end{
    display: flex !important;
    justify-content: flex-end !important;
}

.justify-between{
    justify-content: space-between !important;
}

.pl-5{
    padding-left: 5px;
} 
.pr-5{
    padding-right: 5px;
}

.ant-calendar-picker-clear {
    display: none;
}

@media (max-width: 767.98px){
    .ant-modal {
        width: 70% !important;
    }
    .font-18{
        font-size: 18px !important;
    }
    .font-20{
        font-size: 20px !important;
    }
    .font-22{
        font-size: 22px !important;
    }
}

.d-flex {
    display: flex !important;
    align-items: center;
}

.direction-column{
    flex-direction: column !important;
}

.direction-row{
    flex-direction: row !important;
}

.align-end{
    align-items: flex-end !important;
}

.align-center{
    align-items: center !important;
}

.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-wrap{
    word-break: break-all;
}

.ml-0{
    margin-left: 0px !important;
}

.ml-10{
    margin-left: 10px !important; 
}

.w-100 {
    width: 100%;
}

.w-80 {
    width: 80%;
}

.w-50 {
    width: 50%;
}

.title-ellipse{
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font-16 {
    font-size: 16px;;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-20{
    margin-top:20px;
}

@media screen and (min-width:567px){
.ml-12{
    margin-left:-12px;
}
}

.height-100 {
    height: 100% !important;
}

.flex-wrap{
    flex-wrap: wrap;
}

/*log info*/
.ant-select{
    width: 100% !important;
    .ant-select-selection{
        background: #f4f4f6;
        box-shadow: none;
        height: 45px;
        display: flex;
        align-items: center;
    }
}
.ant-select-dropdown{
    background-color: #f4f4f6 !important;
}

/* font sizes */
.font-18 {
    font-size: 18px;
}

/* margin */
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
