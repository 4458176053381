.myprofile-uploadedimg{
  position: relative;
  text-align: center;
  .ant-avatar-circle{
    height: 80px;
    width: 80px;
  } 
}
.myprofile-uploadedimg:hover{
  opacity: 0.5;
  filter: alpha(opacity=50); 
}

.userprofile-text {
   width:80px; 
   height:80px;
   border-radius: 10px;
   color: $white !important;
    font-size: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .userprofile-text:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black !important;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
  }
  .userprofile-hobbies-hint{
    color: $grey3;
    font-size: 12px;
    margin-top:0px;
    align-items: flex-end !important;
  }
.userprofile-flex{
  display: flex !important;
  align-items: center!important;
}

.clientProfile{
  width: 80px !important;
  height: 80px !important;
}

.clientProfile.modal{
  width:90%!important;
  height:350px!important;
  margin: 40px 0px;
  object-fit: contain;
  img{
    object-fit: contain;
  }
}
.profileFeildDesc{
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.watermargin .has-error .ml-0 {
  margin-left: 0px !important;
}

.select-gender{
  width: 135px !important;
}

.profile-input-row{
  .flag-dropdown{
    height: 35px;
    margin-top: 2.8px;
  }
  .form-control{
  width:100%!important;
  }
  .react-tel-input :disabled {
    cursor: not-allowed;
    color:rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5!important;
  }
}


@media screen and (max-width: 767.98px){
  .profile-input-row{
    width: 100%;
    div:nth-of-type(2) {
      width: 100%;
    }
  }
  .myprofile-uploadedimg{
    .ant-avatar-circle{
      height: 60px;
      width: 60px;
    } 
  }
  .clientProfile{
    width: 60px !important;
    height: 60px !important;
  }
  .profileFeildDesc{
    display: none;
  }
}

.deleteModal {
  padding: 40px 20px;
  p{
    font-size: 22px;
    font-weight: bold;
  }
  .ant-btn{
    width: 100px;
  }
  .btn-yes{
    margin-right: 20px;;
  }
}

.clearfix{
  .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }
    
    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  
    .ant-upload-list{
      display: block !important;
    }
  
    .ant-modal-content{
        padding: 10px 0px !important;
    }
  }

.background-base {
  padding:10px;
  margin-bottom:0px!important;
  margin-top: 10px;
  min-height: 70px;
  object-fit: contain;
  border-radius: 16px;
  background-color: $grey1;
}

.water-label{
  min-height: 70px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.log-Question {
  font-family: $primaryFontFamily;
  font-size: $fontSizeH5;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: $grey5;
  margin-bottom: 0px;
  margin-right: 10px;
}

.image-align{
  display: flex !important;
  align-items:center;
  justify-content: space-between;
}

.text{
  width:100%;
  display:flex;
}

.verticallinewater{
  border-left:1px solid $peach3 ;
  min-height:50px;
  height:100%;
  margin-right: 30px;
}

.logsave{
  display: flex;
  justify-content: flex-end;
}

.profile-save{
  margin-right:15px;
}

@media  screen and (max-width:768px){
.logsave{
  display: flex;
  justify-content: flex-start;
}

.log-Edit{
  text-align: left;
}
}

.log-Edit {
  font-family: $primaryFontFamily;
  font-size: $fontSizeH6;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  color: $peach4;
  margin-bottom: 0px;
  margin-top: 1px;
  cursor: pointer;
}