@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
@import url(https://unpkg.com/react-phone-input-2@2.x/dist/lib.js);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}  

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* COLORS */
/* gradient */
/* FONT-FAMILY */
/*FONT SIZES*/
.BN-Logos {
  width: 200px; }

.login .ant-form {
  padding-top: 10px; }

.login .ant-form-item .ant-form-item-with-help {
  margin-bottom: 10px !important; }

.login .has-error .ant-form-explain {
  margin-top: 5px; }

.login .ant-btn:hover, .login .ant-btn:focus, .login .ant-btn:active {
  height: 56px;
  object-fit: contain;
  border-radius: 14px;
  background-color: #67dbb4;
  margin-top: 20px; }

.login .ant-btn {
  width: 80%;
  height: 56px;
  object-fit: contain;
  border-radius: 14px;
  background-color: #67dbb4;
  margin-top: 20px; }

.login .ant-input {
  height: 56px;
  border-radius: 9px;
  background-color: #f4f4f6;
  margin-top: 10px; }

.login .ant-btn:disabled {
  height: 56px;
  object-fit: contain;
  border-radius: 14px;
  opacity: 0.7;
  background-color: #67dbb4 !important;
  margin-top: 20px; }

.login .ant-btn > span {
  display: inline !important; }

.centerAlign {
  text-align: center; }

.login-now {
  height: 26px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: center;
  color: #fff; }

.login-text {
  margin-bottom: 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #2e3349; }

.forgot-text {
  margin-bottom: 2.5rem; }

.flex-center {
  padding: 50px 20px 70px;
  display: flex !important;
  flex-direction: column !important; }
  @media screen and (min-width: 576px) {
    .flex-center {
      justify-content: center !important; } }

.forgetpassword-Label {
  height: 22px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #0095c4;
  margin-top: 10px; }

.loginbackground {
  padding: 40px 0px;
  border-radius: 10px;
  box-shadow: 3px 10px 26px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff; }

.appstore {
  width: 150px !important; }
  @media screen and (min-width: 768px) {
    .appstore {
      width: 250px !important; } }
  @media screen and (min-width: 576px) {
    .appstore {
      width: 250px !important; } }

.login {
  background-color: #f4f4f6;
  min-height: 100vh !important;
  height: auto !important;
  display: flex !important; }
  @media screen and (min-width: 768px) {
    .login {
      align-items: center; } }
  @media screen and (min-width: 576px) {
    .login {
      height: auto !important; } }

.ant-input:placeholder-shown {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #212121 !important; }

/*profile*/
.myprofile-text {
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #2e3349; }

.myprofile-info {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2e3349;
  margin-top: 22px; }

.mydescription {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #85868f; }

.bottom {
  margin-bottom: 10px; }

.profile-main .ant-form-item {
  margin-bottom: 0px !important; }

/* Change Password */
.pwdbackground {
  padding: 40px 40px;
  background: #fff;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important; }

.changepassword {
  padding: 10px; }
  .changepassword .ant-btn:disabled {
    width: 100% !important;
    height: 54px;
    object-fit: contain;
    border-radius: 14px;
    opacity: 0.7;
    background-color: #67dbb4 !important;
    margin-top: 20px; }
  .changepassword .ant-btn, .changepassword .ant-btn:focus, .changepassword .ant-btn:hover, .changepassword .ant-btn:active {
    width: 100% !important;
    height: 54px;
    object-fit: contain;
    border-radius: 9px;
    background-color: #67dbb4 !important;
    margin-top: 20px; }
  .changepassword .has-error .ant-form-explain {
    margin-top: 5px; }

.passwordform {
  padding: 30px 0px !important;
  width: 100%; }

.changepassword-text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #2e3349; }

.password-input {
  height: 50px !important;
  border-radius: 9px !important;
  background-color: #f4f4f6 !important;
  border-color: #f4f4f6 !important; }

.password-head {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2e3349; }

.savepassword {
  height: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

@media screen and (max-width: 575.98px) {
  .login-now {
    height: 26px;
    font-size: 2.5vw; }
  .login .ant-btn {
    width: 100%; }
  .flex-center {
    display: flex !important;
    flex-direction: column !important;
    justify-content: baseline !important;
    padding: 40px 20px; }
  .BN-Logos {
    width: 150px; } }

.flex-page {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 75vh !important; }

@media screen and (max-width: 767.98px) {
  .pwdbackground {
    padding: 20px 10px; } }

.profile-user-loading .ant-spin-dot-item {
  background: #fa4d6a !important; }

/* COLORS */
/* gradient */
/* FONT-FAMILY */
/*FONT SIZES*/
/* COLORS */
/* gradient */
/* FONT-FAMILY */
/*FONT SIZES*/
.fullheight {
  height: 100% !important; }

.img {
  height: 85px;
  width: 85px;
  align-self: center; }

.imgline {
  height: 3px;
  width: 100%;
  background: #f4f4f6;
  margin-bottom: 10px; }

.imagecontent {
  display: flex;
  justify-content: center; }

.center-align {
  display: flex;
  justify-content: center;
  text-align: center; }

.icontext {
  height: 24px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
  color: #767c8e; }

.center-align.ant-menu-item {
  height: 94px;
  width: 110px;
  background-color: #fff !important;
  border-radius: 20px;
  padding-left: 16px !important; }

.ant-menu-item-selected {
  background-image: linear-gradient(131deg, #67dbb4, #67dbb4) !important;
  box-shadow: 0px 6px 8px 0 #fb5d5d, 0.4; }

.svg {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  margin-top: 15px;
  text-align: center; }

.ant-menu-item-selected .colorsvg g, .ant-menu-item-selected .colorsvg g g, .ant-menu-item-selected .colorsvg g use {
  fill: #fff; }

.ant-menu-item-selected .colorsvg path {
  fill: #fff; }

.colorsvg g, .colorsvg g g, .colorsvg g use {
  fill: #85868f; }

.ant-menu-inline .ant-menu-item::after {
  border-right-color: #67dbb4 !important; }

.ant-menu-item-selected .icontext {
  color: #fff !important;
  font-weight: bold; }

.sidebar1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px !important;
  background-color: #fff;
  overflow: scroll;
  overflow-x: hidden; }

.fullheight .ant-menu-inline .ant-menu-item {
  height: 90px !important;
  width: 110px !important; }

.ant-layout-sider {
  flex: 0 0 !important;
  background-color: #f4f4f6 !important;
  min-width: 140px !important;
  height: 100%; }

.fullheight .anticon svg {
  width: 30px !important;
  height: 30px !important;
  color: #85868f !important; }

.fullheight .anticon svg:active, .fullheight .anticon svg:focus {
  color: #fff !important; }

.fullheight .ant-menu-item-active {
  color: #fff !important; }

.ant-layout-sider-zero-width-trigger {
  top: 2px !important;
  right: 79px !important;
  border: 1px solid; }

/* mobile navbar */
.topNav {
  margin-top: 0px !important;
  border-radius: 0px !important;
  z-index: 999;
  position: fixed !important;
  width: 100%; }
  .topNav .menuDivision {
    display: flex;
    align-items: center; }
    .topNav .menuDivision .menu-text-row {
      text-align: center;
      width: 100%; }
    .topNav .menuDivision .pageName {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #2f3349;
      padding-right: 22px; }
    .topNav .menuDivision .anticon svg {
      height: 22px;
      width: 22px; }
  .topNav .listView {
    display: none; }
  .topNav .listView.active {
    display: flex;
    margin-top: 30px; }
  .topNav .fullheight .ant-menu-inline .ant-menu-item.ant-menu-item-selected .icontext {
    font-weight: 600; }
  .topNav .fullheight .ant-menu-inline .ant-menu-item {
    display: flex;
    justify-content: flex-start;
    width: 100% !important;
    background-image: linear-gradient(131deg, #fafafa, #fafafa);
    height: auto !important; }
    .topNav .fullheight .ant-menu-inline .ant-menu-item a {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .topNav .fullheight .ant-menu-inline .ant-menu-item a .icontext {
        margin-bottom: 0px;
        margin-left: 35px;
        height: auto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal; }
  .topNav .ant-menu.fullheight {
    width: 100% !important;
    border: none; }
    .topNav .ant-menu.fullheight .imagecontent {
      display: none; }
    .topNav .ant-menu.fullheight .imgline {
      height: 0px; }
  .topNav .sideMenuDiv {
    margin-top: 15px; }
  .topNav .ant-dropdown-placement-bottomLeft {
    margin-top: 25px; }
  .topNav .profileDropDown {
    background-color: #f4f4f6;
    border-radius: 7px; }
    .topNav .profileDropDown .profile {
      display: flex;
      justify-content: space-between;
      padding: 8px; }
      .topNav .profileDropDown .profile i {
        margin-left: 0px; }
    .topNav .profileDropDown .listView {
      margin: 0px; }
    .topNav .profileDropDown ul {
      list-style: none;
      padding: 0px;
      width: 100%; }
      .topNav .profileDropDown ul li {
        text-align: left;
        padding: 10px 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #212121; }
    .topNav .profileDropDown .horizontal-divider {
      background-color: #e5e6eb !important;
      box-shadow: none;
      margin: 5px 0px; }
    .topNav .profileDropDown .anticon-right svg {
      height: 14px;
      width: 14px; }
    .topNav .profileDropDown .anticon svg {
      height: 18px;
      width: 18px; }

.desktop-menu {
  height: 100%; }

.header-left-align {
  font-family: "Montserrat", sans-serif !important;
  font-size: 26px;
  font-weight: bold;
  color: #2e3349;
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
  margin-bottom: 10px; }

.info-left-align {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  text-align: left;
  color: #85868f;
  margin-bottom: 0px !important; }

.ant-btn {
  font-weight: bold !important; }

.ant-modal-content {
  border-radius: 16px !important; }
  .ant-modal-content .ant-modal-body {
    padding: 0 !important; }
    .ant-modal-content .ant-modal-body .ant-btn.save {
      border: none !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.align-items-start {
  align-items: flex-start !important; }

.pointer {
  cursor: pointer; }

.justify-center {
  justify-content: center; }

.justify-start {
  justify-content: flex-start !important; }

.justify-end {
  display: flex !important;
  justify-content: flex-end !important; }

.justify-between {
  justify-content: space-between !important; }

.pl-5 {
  padding-left: 5px; }

.pr-5 {
  padding-right: 5px; }

.ant-calendar-picker-clear {
  display: none; }

@media (max-width: 767.98px) {
  .ant-modal {
    width: 70% !important; }
  .font-18 {
    font-size: 18px !important; }
  .font-20 {
    font-size: 20px !important; }
  .font-22 {
    font-size: 22px !important; } }

.d-flex {
  display: flex !important;
  align-items: center; }

.direction-column {
  flex-direction: column !important; }

.direction-row {
  flex-direction: row !important; }

.align-end {
  align-items: flex-end !important; }

.align-center {
  align-items: center !important; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-wrap {
  word-break: break-all; }

.ml-0 {
  margin-left: 0px !important; }

.ml-10 {
  margin-left: 10px !important; }

.w-100 {
  width: 100%; }

.w-80 {
  width: 80%; }

.w-50 {
  width: 50%; }

.title-ellipse {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.font-16 {
  font-size: 16px; }

.mt-10 {
  margin-top: 10px !important; }

.mt-20 {
  margin-top: 20px; }

@media screen and (min-width: 567px) {
  .ml-12 {
    margin-left: -12px; } }

.height-100 {
  height: 100% !important; }

.flex-wrap {
  flex-wrap: wrap; }

/*log info*/
.ant-select {
  width: 100% !important; }
  .ant-select .ant-select-selection {
    background: #f4f4f6;
    box-shadow: none;
    height: 45px;
    display: flex;
    align-items: center; }

.ant-select-dropdown {
  background-color: #f4f4f6 !important; }

/* font sizes */
.font-18 {
  font-size: 18px; }

/* margin */
.mb-20 {
  margin-bottom: 20px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

/* COLORS */
/* gradient */
/* FONT-FAMILY */
/*FONT SIZES*/
.dashboard-header-left-align {
  font-family: "Montserrat", sans-serif !important;
  font-size: 26px;
  font-weight: bold;
  color: #2e3349;
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
  margin-bottom: 10px; }

.dashboard-info-left-align {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  text-align: left;
  color: #85868f;
  margin-bottom: 0px !important; }

.dashboard-dropdown {
  background-color: #f4f4f6;
  height: 55px;
  display: flex !important;
  flex-direction: column;
  padding: 15px !important;
  padding-right: 0px !important;
  justify-content: center; }

.dashboard-coach-name-dropdown {
  margin-left: 10px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.dashboard-notification-divider {
  height: 55px;
  width: 5px;
  border-right: 2px solid #fff; }

.dashboard-notification {
  height: 55px;
  background-color: #f4f4f6;
  display: flex !important;
  align-items: center;
  justify-content: center; }
  .dashboard-notification .ant-badge-count {
    margin-top: 1px;
    margin-right: 0px; }

.dashboard-myprofile-arrow {
  width: 10.8px;
  height: 18px;
  margin-left: 20px;
  padding-right: 20px; }

.dashboard-calendar-info {
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff; }

.dashboard-coach-info {
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .dashboard-coach-info .coach-horizontal-divider {
    height: 1px !important;
    background: #c3c5d1; }

.dashboard-coach-img {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer !important; }

.dashboard-svg {
  width: 22.7px;
  height: 30px; }
  .dashboard-svg g, .dashboard-svg g g, .dashboard-svg g use {
    fill: #fb4371; }

.dashboard-msg-svg {
  width: 26px;
  height: 18.5px;
  margin-top: 2px; }
  .dashboard-msg-svg g, .dashboard-msg-svg g g, .dashboard-msg-svg g use {
    fill: #fb4371; }

.dashboard-log-header {
  font-family: "Montserrat", sans-serif !important;
  color: #fa4d6a;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer !important; }

.space {
  margin-left: 8px; }

.dashboard-log-info {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  color: #2e3349; }

.dashboard-calendar-margin {
  padding: 20px; }

.dashboard-coach-margin {
  padding: 20px; }

.dashboard-calendar-divider {
  border-left: 1px solid #c3c5d1;
  height: 225px;
  width: 2px;
  float: right;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-self: center; }

.dashboard-message {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

.calender-date-row {
  margin: 10px; }

@media screen and (min-width: 568px) and (max-width: 1024px) {
  .dashboard-message {
    flex-direction: column; }
  .dashboard-msg-svg {
    margin-left: -10px; }
  .space {
    margin-left: 0px !important; } }

@media screen and (max-width: 320px) {
  .dashboard-log-header {
    margin-left: 8px; } }

.dashboard-calendar-align {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.dashboard-coach-name {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  font-weight: bold; }

.dashboard-personal-coach {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px; }

.dashboard-log-align {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100%; }

.dashboard-menu .ant-menu-item {
  height: 10px !important; }

.dashboard-calendar-bg {
  width: 60px;
  height: 60px;
  border-radius: 33px;
  background-color: #f4f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px; }
  .dashboard-calendar-bg img {
    height: 25px !important;
    width: 25px !important;
    display: unset !important; }

.calendar-arrow {
  width: 11px;
  height: 18px;
  color: #2e3349;
  margin: 10px; }

.calendar-date {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  color: #2e3349; }

.calendar-day {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  line-height: 1.47; }

.dashboard-menu {
  display: flex;
  background-color: #f4f4f6;
  align-items: center;
  width: 100%; }

.dasboard-padding {
  padding: 8px; }

.dashboard-dropdown-align {
  display: flex !important;
  align-items: center;
  flex-direction: row; }

.dashboard-menu-label {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  color: #000000 !important; }

.dashboard-alert-size {
  width: 24px;
  height: 24px; }
  .dashboard-alert-size g, .dashboard-alert-size g g, .dashboard-alert-size g use {
    fill: #85868f; }

.dashboard-calendar-size {
  width: 26px;
  height: 26px; }
  .dashboard-calendar-size g, .dashboard-calendar-size g g, .dashboard-calendar-size g use {
    fill: #2e3349; }

.dashboard-menu .ant-layout {
  background-color: #f4f4f6 !important; }

.dashboard-menu .ant-row:hover {
  background-color: #fff !important; }

.dashboard-menu a:hover {
  color: #000 !important; }

.ant-dropdown-menu {
  background-color: #f4f4f6 !important; }

.ant-dropdown {
  top: 108px !important;
  margin-left: -25px !important; }

.ant-dropdown-menu-item:hover {
  background: #fff !important; }

.dashboard-nocoach {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.dashboard-weight-log-btn {
  background-image: linear-gradient(102deg, #0ca7d7, #0095c4) !important;
  color: #fff !important;
  font-size: 14px; }

.dashboard-graph-align-center {
  display: flex !important;
  justify-content: center; }

.dashboard-graph-label {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  align-items: center;
  padding-top: 10px !important;
  font-weight: normal !important; }

.log-coach-card {
  display: flex !important;
  justify-content: space-between;
  flex-direction: row; }

@media screen and (max-width: 767.98px) {
  .log-coach-card {
    flex-direction: column; }
  .dashboard-coach-info {
    margin-top: 20px; }
  .dashboardCard {
    display: none !important; } }

@media screen and (min-width: 568px) and (max-width: 992px) {
  .calendar-arrow {
    margin: 5px; }
  .calendar-date {
    font-size: 16px; }
  .calender-date-row {
    margin: 10px 0px; } }

.profile-div .ant-avatar {
  width: 40px !important;
  display: inline-block !important; }

.dashboardpage .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff !important;
  background: linear-gradient(131deg, #fb7f3e, #fa496e) !important;
  border-color: #fa4c6a !important; }

.dashboard-card {
  width: 100% !important;
  height: 300px !important; }

.myclientsDropdown {
  background-color: #f4f4f6;
  border-radius: 5px; }

.waterlog {
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px; }

.watermargin {
  padding: 10px 20px; }
  .watermargin .waterMargin-row {
    display: flex;
    align-items: center;
    flex-direction: row; }
  .watermargin .align-right {
    text-align: right; }

@media screen and (max-width: 767.98px) {
  .waterlog .watermargin.p-0 {
    padding: 0px; }
  .waterlog .watermargin {
    padding: 20px; }
    .waterlog .watermargin .titleheader .header {
      text-align: left; }
    .waterlog .watermargin .info {
      text-align: left; }
    .waterlog .watermargin .watercount {
      display: block; }
      .waterlog .watermargin .watercount .countbuttons {
        margin-left: 0px; }
    .waterlog .watermargin .waterMargin-row {
      flex-direction: column; }
    .waterlog .watermargin .align-right {
      text-align: left; }
  .contentstyle {
    margin-top: 70px !important; } }

.dashboardpage .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #40a9ff;
  border-color: #40a9ff; }

.myprofile-uploadedimg {
  position: relative;
  text-align: center; }
  .myprofile-uploadedimg .ant-avatar-circle {
    height: 80px;
    width: 80px; }

.myprofile-uploadedimg:hover {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.userprofile-text {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  color: #fff !important;
  font-size: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.userprofile-text:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.userprofile-hobbies-hint {
  color: #767c8e;
  font-size: 12px;
  margin-top: 0px;
  align-items: flex-end !important; }

.userprofile-flex {
  display: flex !important;
  align-items: center !important; }

.clientProfile {
  width: 80px !important;
  height: 80px !important; }

.clientProfile.modal {
  width: 90% !important;
  height: 350px !important;
  margin: 40px 0px;
  object-fit: contain; }
  .clientProfile.modal img {
    object-fit: contain; }

.profileFeildDesc {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.watermargin .has-error .ml-0 {
  margin-left: 0px !important; }

.select-gender {
  width: 135px !important; }

.profile-input-row .flag-dropdown {
  height: 35px;
  margin-top: 2.8px; }

.profile-input-row .form-control {
  width: 100% !important; }

.profile-input-row .react-tel-input :disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5 !important; }

@media screen and (max-width: 767.98px) {
  .profile-input-row {
    width: 100%; }
    .profile-input-row div:nth-of-type(2) {
      width: 100%; }
  .myprofile-uploadedimg .ant-avatar-circle {
    height: 60px;
    width: 60px; }
  .clientProfile {
    width: 60px !important;
    height: 60px !important; }
  .profileFeildDesc {
    display: none; } }

.deleteModal {
  padding: 40px 20px; }
  .deleteModal p {
    font-size: 22px;
    font-weight: bold; }
  .deleteModal .ant-btn {
    width: 100px; }
  .deleteModal .btn-yes {
    margin-right: 20px; }

.clearfix .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999; }

.clearfix .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666; }

.clearfix .ant-upload-list {
  display: block !important; }

.clearfix .ant-modal-content {
  padding: 10px 0px !important; }

.background-base {
  padding: 10px;
  margin-bottom: 0px !important;
  margin-top: 10px;
  min-height: 70px;
  object-fit: contain;
  border-radius: 16px;
  background-color: #f4f4f6; }

.water-label {
  min-height: 70px !important;
  display: flex !important;
  align-items: center;
  justify-content: center; }

.log-Question {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #2e3349;
  margin-bottom: 0px;
  margin-right: 10px; }

.image-align {
  display: flex !important;
  align-items: center;
  justify-content: space-between; }

.text {
  width: 100%;
  display: flex; }

.verticallinewater {
  border-left: 1px solid #fb4371;
  min-height: 50px;
  height: 100%;
  margin-right: 30px; }

.logsave {
  display: flex;
  justify-content: flex-end; }

.profile-save {
  margin-right: 15px; }

@media screen and (max-width: 768px) {
  .logsave {
    display: flex;
    justify-content: flex-start; }
  .log-Edit {
    text-align: left; } }

.log-Edit {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  color: #fa4d6a;
  margin-bottom: 0px;
  margin-top: 1px;
  cursor: pointer; }

.profileDetails .side-btn-green {
  background-color: #67dbb4 !important; }

.profileDetails .side-btn-opt {
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid lightgray; }

.profileDetails hr {
  margin: 25px 0px; }

.profileDetails .watermargin {
  padding: 30px 20px; }

.profileDetails .uploadedVideos {
  padding: 30px 0px; }

.profileDetails .profile-left-card {
  border-right: 1px solid #0000004d !important;
  height: 100vh; }

.profileDetails .detail-card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
  font-weight: 500; }
  .profileDetails .detail-card .ant-card-head-title {
    font-size: 20px; }
  .profileDetails .detail-card .cardFields {
    display: flex;
    align-items: flex-end;
    padding: 0px 20px;
    margin-bottom: 10px; }
    .profileDetails .detail-card .cardFields .feildData {
      border-bottom: 1px solid #85868f94; }
    .profileDetails .detail-card .cardFields p {
      margin-bottom: 0px;
      color: #000000de; }
  .profileDetails .detail-card input {
    border-top: none;
    border-left: none;
    border-radius: 0px;
    border-right: none;
    font-size: 16px;
    color: #323232;
    font-weight: 600;
    pointer-events: none; }

.profileDetails .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background-color: white; }

.profileDetails .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: #67dbb4 !important; }

.profileDetails .bg-green {
  background-color: #67dbb4 !important; }

.profileDetails .ant-card-small {
  width: 500px;
  margin: 30px 0px !important;
  padding: 0px !important; }

.profileDetails .ant-select-arrow {
  display: none; }

.uploadedVideos .ant-radio-inner::after {
  background-color: #40a9ff; }

.uploadedVideos .actionBtns {
  width: 10%; }

.videoBtns {
  background-color: #67dbb4 !important;
  color: #ffffff !important;
  padding: 15px 20px 35px 15px !important; }

/*table data text center*/
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  text-align: center !important; }

.uploadedVideos .ant-table-thead > tr > th:nth-child(2), .uploadedVideos .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important; }

.anticon-left {
  margin-right: 10px; }

.videoModal .react-player {
  padding: 50px 30px 30px; }

.calender-range-picker {
  width: 100%;
  height: 100% !important;
  margin-left: 20px !important; }
  .calender-range-picker .ant-calendar-picker-input.ant-input {
    height: 100%; }
    .calender-range-picker .ant-calendar-picker-input.ant-input .ant-calendar-range-picker-separator {
      vertical-align: middle;
      padding: 5px 0px; }

.name-search-input {
  height: 100% !important;
  margin-left: 20px !important; }

